import React, { Fragment, memo, PureComponent } from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled, css } from '@compiled/react';

import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner/spinner';
import { Box, xcss } from '@atlaskit/primitives';

import type {
	DragPreview,
	DragEnabledProp,
	RenderItem,
	RenderItemEmptyState,
	TreeObject,
} from '@confluence/tree';
import { Tree, TreeMonitorLoader } from '@confluence/tree';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { SetSSRPartialSuccess, SSR_PARTIAL_COMPONENT } from '@confluence/ssr-utilities';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';

import type { ContentTreeItem } from './data-transformers';

const INDENT_PER_LEVEL = 12;

const INDENT_PER_LEVEL_NAV_3 = 16;

// Container to expand Page tree to the remaining space
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AkTreeContainerFlex = styled.div({
	/* Grows to fill up the remaining space */
	flexGrow: 1,
	/* Expands with the content */
	flexShrink: 0,
	/* Expands narrow tree to at least the width of the sidebar */
	width: '100%',
	/* Turns on flex so child can grow too */
	display: 'flex',
	flexDirection: 'column',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		/* Grow atlaskit/tree div vertically, so user can go out of bound on the bottom*/
		flexGrow: 1,

		/* Limits the size of the content to its container. Remaining text gets truncated. */
		width: '100%',
	},
});

// SPINNER_SIZE + 8px => to match the medium (24px) chevron.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadMorePagesButtonWrapper = styled.div({
	margin: '10px 0 10px 0',
	width: '100%',
});

const inlineTreeSpinnerStyles = xcss({
	width: '100%',
	minHeight: '24px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexGrow: 1,
});

const pageTreeContainerCSS = css({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	flexShrink: 0,
	alignItems: 'flex-start',
});
const pageTreeContainerLoadingCSS = css({
	opacity: 0.5,
	pointerEvents: 'none',
	cursor: 'not-allowed',
});

export const StyledPageTreeContainer = ({ children, loading }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<div
			css={[pageTreeContainerCSS, loading && pageTreeContainerLoadingCSS]}
			data-testid="page-tree-container"
			data-vc="page-tree-container"
			{...ssrPlaceholderIdProp}
		>
			{children}
		</div>
	);
};

const InlineTreePaginationSpinner: FC = () => (
	<Box xcss={inlineTreeSpinnerStyles}>
		<Spinner size="medium" testId="page-tree-loading" />
	</Box>
);

export type PageTreeComponentProps = {
	isDraggable: DragEnabledProp<ContentTreeItem>;
	isTreeTruncatedPrevious?: boolean;
	isTreeTruncatedFollowing?: boolean;
	loadPreviousPages?(): void;
	loadFollowingPages?(): void;
	onCollapse(page: ContentTreeItem): void;
	onExpand(page: ContentTreeItem): void;
	onDragStart: () => void;
	onDragEnd: Function;
	tree: TreeObject<ContentTreeItem>;
	refactor?: boolean;
	loading?: boolean;
	renderItem: RenderItem<ContentTreeItem>;
	renderItemEmptyState?: RenderItemEmptyState<ContentTreeItem>;
	onInitialLoadComplete?(number): void;
	dragPreview?: DragPreview<ContentTreeItem>;
	setIsDraggingItem?: (state: boolean) => void;
	setOpenQuickActionsId?: (id: string | null) => void;
};

const LoadPreviousPagesButton = memo(({ loadMorePages }: { loadMorePages?: () => void }) => {
	return (
		<LoadMorePagesButtonWrapper>
			<Button shouldFitContainer spacing="compact" onClick={loadMorePages}>
				<FormattedMessage
					id="page-tree.pages.show.more.above"
					defaultMessage="Show more above"
					description="Button to load all previous pages that come before the currently shown page tree"
				/>
			</Button>
		</LoadMorePagesButtonWrapper>
	);
});

const LoadFollowingPagesButton = memo(({ loadMorePages }: { loadMorePages?: () => void }) => {
	return (
		<LoadMorePagesButtonWrapper>
			<Button shouldFitContainer spacing="compact" onClick={loadMorePages}>
				<FormattedMessage
					id="page-tree.pages.show.more.below"
					defaultMessage="Show more below"
					description="Button to load all following pages that come before the currently shown page tree"
				/>
			</Button>
		</LoadMorePagesButtonWrapper>
	);
});

/**
 * This is a purely presentational component. Please don't add any
 * non-presentational logic _directly_ to this component - use optional
 * callback props instead.
 */
export class PageTreeComponent extends PureComponent<PageTreeComponentProps> {
	componentDidMount() {
		const { tree, refactor } = this.props;
		if (refactor && Object.keys(tree.items).length) {
			this.props.onInitialLoadComplete?.(Object.keys(tree.items).length);
			this.onInitialLoadCompleteSubmitted = true;
		}
	}

	componentDidUpdate(prevProps: PageTreeComponentProps) {
		if (this.onInitialLoadCompleteSubmitted) {
			return;
		}

		const { tree } = this.props;

		const justFinishedLoading =
			(prevProps.loading && !this.props.loading) || window.__SSR_RENDERED__;
		if (justFinishedLoading) {
			this.props.onInitialLoadComplete?.(Object.keys(tree.items).length);
			this.onInitialLoadCompleteSubmitted = true;
		}
	}

	private onInitialLoadCompleteSubmitted = false;

	collapsePage = (pageId) => {
		const { tree, onCollapse } = this.props;
		onCollapse(tree.items[pageId]);
	};

	expandPage = (pageId) => {
		const { tree, onExpand } = this.props;
		onExpand(tree.items[pageId]);
	};

	dragEnd = async (source, destination, instruction) => {
		const { onDragEnd } = this.props;
		onDragEnd(source, destination, instruction);
	};

	render() {
		const {
			tree,
			loadPreviousPages,
			loadFollowingPages,
			isTreeTruncatedPrevious,
			isTreeTruncatedFollowing,
			isDraggable,
			dragPreview,
			loading,
		} = this.props;

		const isNav4Enabled = getIsNav4Enabled();

		return (
			<StyledPageTreeContainer loading={loading}>
				{!isTreeTruncatedPrevious && !isTreeTruncatedFollowing && loading && (
					<InlineTreePaginationSpinner />
				)}
				{isTreeTruncatedPrevious &&
					(!loading ? (
						<LoadPreviousPagesButton loadMorePages={loadPreviousPages} />
					) : (
						<InlineTreePaginationSpinner />
					))}
				<AkTreeContainerFlex data-testid="tree-container-flex">
					<Fragment>
						<Tree<ContentTreeItem>
							tree={tree}
							isDragEnabled={isDraggable}
							renderItem={this.props.renderItem}
							renderItemEmptyState={this.props.renderItemEmptyState}
							onExpand={this.expandPage}
							onCollapse={this.collapsePage}
							onDragStart={this.props.onDragStart}
							onDragEnd={this.dragEnd}
							dragPreview={dragPreview}
							indentPerLevel={isNav4Enabled ? INDENT_PER_LEVEL : INDENT_PER_LEVEL_NAV_3}
						/>
						<TreeMonitorLoader setIsDraggingItem={this.props.setIsDraggingItem} />
					</Fragment>
				</AkTreeContainerFlex>
				{isTreeTruncatedFollowing &&
					(!loading ? (
						<LoadFollowingPagesButton loadMorePages={loadFollowingPages} />
					) : (
						<InlineTreePaginationSpinner />
					))}
				{!loading && <SetSSRPartialSuccess name={SSR_PARTIAL_COMPONENT.PageTree} />}
			</StyledPageTreeContainer>
		);
	}
}
